import React from 'react'

const Host = () => {
    
    return (
        <div className="host">

        </div>
    )
}

export default Host