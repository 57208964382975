import React, { useEffect,useState } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../../firebase'
import numeral from 'numeral'

const Item = (props) => {

    const {data} = props

    return (
        <li onClick={() => props.handleSelect(data.cover)}>
            <picture>
                <img src={data.cover} alt={data.name}/>
            </picture>
            <div className="container">
                <h4>{data.name}</h4>
                <p>
                    <span>Habitaciones desde </span>
                    <b>{numeral(data.minPrice).format('$0,0.00')} / por noche</b>
                </p>
            </div>
        </li>
    )
}

const Home = () => {

    const [hotels,setHotels] = useState([])
    const [selected,setSelected] = useState('')

    useEffect(() => {
        firebase.getCollectionRealTime('hotels').where('isActive','==','true').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setHotels(data)
            setSelected(data[0].cover)
        })
    },[])

    return (
        <div className="home">
            <div className="home__cover">
                <div className="home__cover-container">
                    <h1>Grupo Hotelero Latinoamérica</h1>
                </div>
            </div>
            <div className="home__first">
                <div className="home__first-container">
                    <div className="list">
                        <span>Los mejores hoteles</span>
                        <h3>Nuestros Hoteles</h3>
                        <ul>{
                            Object.keys(hotels).map(key => <Item handleSelect={handleSelect} key={key} data={hotels[key]} />)
                        }</ul>
                    </div>
                    <div className="preview">
                        <div style={{ backgroundImage: `url(${selected})` }} className="preview__container"></div>
                    </div>
                </div>
            </div>
            <div className="home__rooms">
                <div className="home__rooms-container">
                    <h2>Nuestras habitaciones</h2>
                    <ul>
                        <li>
                            <picture>
                                <img src="/images/r1.png" alt="Habitación"/>
                            </picture>
                        </li>
                        <li>
                            <picture>
                                <img src="/images/r2.png" alt="Habitación"/>
                            </picture>
                        </li>
                        <li>
                            <picture>
                                <img src="/images/r3.png" alt="Habitación"/>
                            </picture>
                        </li>
                        <li>
                            <picture>
                                <img src="/images/r4.png" alt="Habitación"/>
                            </picture>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="home__separator">
                <div className="home__separator-container">
                    <h3>Realiza tu reservación en línea</h3>
                    <Link to='/hospedate-en-linea'>Revisa disponibilidad</Link>
                </div>
            </div>
        </div>
    )

    async function handleSelect (value) {
        setSelected(value)
    }
}

export default Home;