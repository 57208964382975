import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';

import Home from './home/Home';
import About from './about/About';
import Contact from './contact/Contact';
import Host from './host/Host';

const App = () => {
    return (
        <div className="app">
            <header>
                <div className="header__container">
                    <picture>
                        <img src="/images/logo.png" alt="Grupo Hotelero Latinoamérica"/>
                    </picture>
                    <ul>
                        <Link to="/"><span>Inicio</span></Link>
                        <Link to="/nosotros"><span>Nosotros</span></Link>
                        <Link to="/hospedate-en-linea"><span>Hospedate en Línea</span></Link>
                        <Link to="/contacto"><span>Contacto</span></Link>
                    </ul>
                </div>
            </header>
            <Switch>
                <Route path="/" component={Home} exact={true} />
                <Route path="/nosotros" component={About} exact={true} />
                <Route path="/hospedate-en-linea" component={Host} exact={true} />
                <Route path="/contacto" component={Contact} exact={true} />
            </Switch>
            <footer>
                <div className="footer-top">
                    <div className="footer-top__container">
                        <ul>
                            <Link to="/">Inicio</Link>
                            <Link to="/nosotros">Nosotros</Link>
                            <Link to="/hospedate-en-linea">Hospedate en Línea</Link>
                            <Link to="/contacto">Contacto</Link>
                        </ul>
                        <ul>
                            <Link to="/">Inicio</Link>
                            <Link to="/nosotros">Nosotros</Link>
                            <Link to="/hospedate-en-linea">Hospedate en Línea</Link>
                            <Link to="/contacto">Contacto</Link>
                        </ul>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="footer-bottom__container">
                        <span>Todos los derechos reservados. &copy; 2019 Grupo Hotelero Latinoamérica</span>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default App;