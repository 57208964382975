import React, { useState,useEffect } from 'react'
import firebase from '../../firebase'

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    const [name,setname] = useState('')
    const [lastName,setlastName] = useState('')
    const [email,setemail] = useState('')
    const [phone,setphone] = useState('')
    const [message,setmessage] = useState('')

    return (
        <div className="contact">
            <div className="contact__container">
                <h1>Contacto</h1>
                <div className="content">
                    <form onSubmit={e => e.preventDefault() && false}>
                        <div className="input-container">
                            <label>Nombre</label>
                            <input value={name} onChange={e => setname(e.target.value)} placeholder="Nombre" type="text"/>
                        </div>
                        <div className="input-container">
                            <label>Apellidos</label>
                            <input value={lastName} onChange={e => setlastName(e.target.value)} placeholder="Apellidos" type="text"/>
                        </div>
                        <div className="input-container">
                            <label>Email</label>
                            <input value={email} onChange={e => setemail(e.target.value)} placeholder="Email" type="email"/>
                        </div>
                        <div className="input-container">
                            <label>Teléfono</label>
                            <input value={phone} onChange={e => setphone(e.target.value)} placeholder="Teléfono" type="text"/>
                        </div>
                        <div className="input-container">
                            <label>Mensaje</label>
                            <textarea value={message} onChange={e => setmessage(e.target.value)} placeholder="Mensaje" rows="5" />
                        </div>
                        <div className="input-container">
                            <button onClick={send} type="submit">Enviar</button>
                        </div>
                    </form>
                    <section>
                        <ul>
                            <li>
                                <i className="material-icons">place</i>
                                <span>Dirección de prueba</span>
                            </li>
                            <li>
                                <i className="material-icons">phone</i>
                                <span>(55) 1234 - 9876</span>
                            </li>
                            <li>
                                <i className="material-icons">mail</i>
                                <a href="mailto:contacto@grupohotelerolatinoamerica.com">contacto@grupohotelerolatinoamerica.com</a>
                            </li>
                        </ul>
                        <iframe title="Mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.5881936348987!2d-99.19795638509035!3d19.430191045792476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d201f8aa181137%3A0xaafebc3fb1ad335d!2sAlejandro+Dumas+71%2C+Polanco%2C+Polanco+IV+Secc%2C+11550+Ciudad+de+M%C3%A9xico%2C+CDMX!5e0!3m2!1ses-419!2smx!4v1565747671538!5m2!1ses-419!2smx" width="100%" height="200" frameBorder="0" allowFullScreen=""></iframe>
                    </section>
                </div>
            </div>
        </div>
    )

    async function send () {
        try {
            const item = {
                name,
                lastName,
                email,
                phone,
                message,
            }
            firebase.sendMessage(item).then(() => {
                alert('Mensaje enviado, un asesor se pondrá en contacto contigo')
                setname('')
                setlastName('')
                setemail('')
                setphone('')
                setmessage('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default Contact